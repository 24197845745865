import { H1, H2, H3, H4, H5, P, Pi, Pr, Pb, Sm } from "../../../../theme/Typography";
import { Bhr } from "../../../../theme/GlobalStyles";
import * as React from 'react';
export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  P,
  Pi,
  Pr,
  Pb,
  Sm,
  Bhr,
  React
};