const theme = {
  colors: {
    // https://coolors.co/generate
    // ${({ theme }) => theme.colors.};
    platinum: `#EAEAEA`, // Platinum
    white: `#FFFFFF`, // White
    ghost: `#F6F7FC`, // Ghost White
    charleston: `#212529`, // Charleston Green
    violet: `#922BFF`, // Vivid Violet
    indigo: `#2B096B`, // Persian Indigo
    pink: `#D42990`, // Barbie Pink
    jet: `#333333`, // Jet
    cultured: `#ECEEF0`, // Cultured | Light grey
    eerie: `#202020`, // Eerie Black
    spanish: `#8E8E8E`, // Spanish Gray
    silver: `#B0B0B0`, // Silver Chalice
    davys: `#5C5C5C`, // Davys Grey
  },
  spacings: {
    // ${({ theme }) => theme.spacings.};
    xxSmall: '.25rem', // 4px
    xSmall: '.5rem', // 8px
    small: '1rem', // 16px
    medium: '2rem', // 32px
    large: '3rem', // 48px
    xLarge: '4rem', // 64px
    xxLarge: '6rem', // 96px
  },
  desktopSizes: {
    // https://type-scale.com/
    // Perfect Fourth
    // ${({ theme }) => theme.desktopSizes.};
    h1: `4.209rem`, // 67.34px
    h2: `3.157rem`, // 50.52px
    h3: `2.369rem`, // 37.90px
    h4: `1.777rem`, // 28.43px
    h5: `1.333rem`, // 21.33px
    p: `1rem`, // 16px
    small: `0.8rem`, // 12px
    xSmall: `0.64rem`, // 9px
    xxSmall: `0.512rem`, // 6.76px
  },
  mobileSizes: {
    // https://type-scale.com/
    // Minor Third
    // ${({ theme }) => theme.mobileSizes.};
    h1: `2.488rem`, // 39.81px
    h2: `2.074rem`, // 33.18px
    h3: `1.728rem`, // 27.65px
    h4: `1.44rem`, // 23.04px
    h5: `1.2rem`, // 19.20px
  },
  breakpoints: {
    sm: `600px`,
    md: `960px`,
    lg: `1280px`,
    xl: `1920px`,
  },
  animations: {
    // ${({ theme }) => theme.animations.trans};
    trans1: `all 0.4s ease-in-out`,
    trans2: `all 0.4s ease`,
    trans3: `0.4s`,
    trans4: `all 550ms cubic-bezier(0.19, 1, 0.22, 1)`,
  },
  shadows: {
    // ${({ theme }) => theme.shadows.shad};
    shad1: `0px 16px 16px rgba(0, 0, 0, 0.1)`,
    shad2: `0px 8px 8px rgba(142, 142, 142, 0.1)`,
    shad3: `0px 4px 4px rgba(0, 0, 0, 0.2)`,
  },
}

export default theme
