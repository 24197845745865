import { createGlobalStyle } from 'styled-components'
import styled from 'styled-components'

// CSS
import './reset.css'
import './font.css'

// Utils
import lineHeightCalc from '../utils/lineHeightCalc'

const GlobalStyles = createGlobalStyle`
body {
  background: radial-gradient(at top left, rgba(146, 43, 225, 1) 0%, rgba(43, 9, 107, 1) 100%);
  background: radial-gradient(at top left, rgba(43, 9, 107, 1) 0%, rgba(146, 43, 225, 1) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  top:0;
  right:0;
  bottom:0;
  left:0;
  font-family: 'Overpass';
}

body::after {
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(at top left, rgba(146, 43, 225, 1) 0%, rgba(43, 9, 107, 1) 100%);
  background: radial-gradient(at top left, rgba(43, 9, 107, 1) 0%, rgba(146, 43, 225, 1) 100%);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 0;
}

html {
   min-height: 100vh;
}

.portDetails {
  h2, h3, h4, h5 {
    font-family: 'Triplepass';
    color: ${({ theme }) => theme.colors.jet};
  }
    
  h2 {
    font-size: ${({ theme }) => theme.desktopSizes.h2};
    line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h2)};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.mobileSizes.h2};
    line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h2)};
    }
  }

  h3 {
    font-size: ${({ theme }) => theme.desktopSizes.h3};
    line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h3)};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.mobileSizes.h3};
      line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h3)};
    }
  }

  h4 {
    font-size: ${({ theme }) => theme.desktopSizes.h4};
    line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h4)};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.mobileSizes.h4};
      line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h4)};
    }
  }

  h5 {
    font-size: ${({ theme }) => theme.desktopSizes.h5};
    line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h5)};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.mobileSizes.h5};
      line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h5)};
    }
  }

  p {
    color: ${({ theme }) => theme.colors.eerie};
    line-height: ${props => lineHeightCalc(props.theme.desktopSizes.p)};
    margin-bottom: ${({ theme }) => theme.spacings.medium};
  }

  img {
    width: 100%;
    box-shadow: ${({ theme }) => theme.shadows.shad3};
  }

  a {
    color: ${({ theme }) => theme.colors.indigo};
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: ${({ theme }) => theme.spacings.xSmall};
    text-decoration-color: ${({ theme }) => theme.colors.spanish};
  }
  a:focus {
    color: ${({ theme }) => theme.colors.indigo};
  }
  a:hover {
    color: ${({ theme }) => theme.colors.pink};
    transition: ${({ theme }) => theme.animations.trans1};
    text-decoration-color: ${({ theme }) => theme.colors.pink};
  }
}

button {
  display: flex;
  justify-content: center;
}
`

export default GlobalStyles

export const Bhr = styled.hr`
  border: 0;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.spanish};
  margin-top: ${({ theme }) => theme.spacings.xLarge};
  margin-bottom: ${({ theme }) => theme.spacings.xLarge};
`
