import React from 'react'
import { ThemeProvider } from 'styled-components'
import Theme from './theme'
import GlobalStyles from './theme/GlobalStyles'

const RootWrapper = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    {element}
  </ThemeProvider>
)

export default RootWrapper
