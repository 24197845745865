import styled from 'styled-components'

// Utils
import lineHeightCalc from '../utils/lineHeightCalc'

export const H1 = styled.h1`
  font-family: 'Triplepass';
  color: ${({ theme }) => theme.colors.pink};
  font-size: ${({ theme }) => theme.desktopSizes.h1};
  line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h1)};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.mobileSizes.h1};
    line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h1)};
  }
`

export const H2 = styled.h2`
  font-family: 'Triplepass';
  color: ${({ theme }) => theme.colors.pink};
  font-size: ${({ theme }) => theme.desktopSizes.h2};
  line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h2)};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.mobileSizes.h2};
    line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h2)};
  }
`

export const H3 = styled.h3`
  font-family: 'Triplepass';
  color: ${({ theme }) => theme.colors.pink};
  font-size: ${({ theme }) => theme.desktopSizes.h3};
  line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h3)};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.mobileSizes.h3};
    line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h3)};
  }
`

export const H4 = styled.h4`
  font-family: 'Triplepass';
  color: ${({ theme }) => theme.colors.pink};
  font-size: ${({ theme }) => theme.desktopSizes.h4};
  line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h4)};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.mobileSizes.h4};
    line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h4)};
  }
`

export const H5 = styled.h5`
  font-family: 'Triplepass';
  color: ${({ theme }) => theme.colors.pink};
  font-size: ${({ theme }) => theme.desktopSizes.h5};
  line-height: ${props => lineHeightCalc(props.theme.desktopSizes.h5)};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.mobileSizes.h5};
    line-height: ${props => lineHeightCalc(props.theme.mobileSizes.h5)};
  }
`

export const P = styled.p`
  color: ${({ theme }) => theme.colors.eerie};
  line-height: ${props => lineHeightCalc(props.theme.desktopSizes.p)};
`

export const Pi = styled(P)`
  text-indent: ${({ theme }) => theme.spacings.medium};
`

export const Pr = styled(P)`
  text-align: right;
`

export const Pc = styled(P)`
  text-align: center;
`

export const Pb = styled(P)`
  margin-bottom: ${({ theme }) => theme.spacings.small};
`

export const Sm = styled.small`
  color: ${({ theme }) => theme.colors.eerie};
  font-size: ${({ theme }) => theme.desktopSizes.small};
  line-height: ${props => lineHeightCalc(props.theme.desktopSizes.small)};
`
